import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import MousePointer from './MousePointer';
import logoPayoff from './logo-payoff-svg.svg';
import './App.css';

function App() {

  let [counter, setCounter] = useState(0);
  let [mousePositionX, setMousePositionX] = useState(0);
  let [mousePositionY, setMousePositionY] = useState(0);
  let animation = useSpring({
    from: { strokeDashoffset: '925' },
    to: async (next, cancel) => {
      await next({ strokeDashoffset: '0' })
      await next({ strokeDashoffset: '925' })
    },
    onRest: () => {
      setCounter(prev => prev < 5 ? ++prev : 0);
    },

    config: {
      mass: 1,
      tension: 5,
      friction: 9,
      clamp: true
    },
  });

  let logoLetters = [];
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-d</title><path d="M7,190.8v-1.9c11.4,0,24.2-5.1,24.2-17.9V30.6C31.2,17.9,18.4,12.2,7,12.2V10.5H72.4S94.2,9.2,108,9.2c41.8,0,85,37.4,85,91.8s-36.4,89.8-89.9,89.8ZM90.6,11.3a101.7,101.7,0,0,0-18.7,1.9V171.3c.3,12.7,5.4,17.1,12.5,17.4H96.1c28.2,0,52.1-17.2,52.1-88.3S116.2,11.3,90.6,11.3Z" /></animated.svg>);
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-a</title><path d="M48.5,139.8,77.9,66.1A31.7,31.7,0,0,0,67.7,55.7V54.4C82.4,42.9,89.1,26.6,95.5,9h1.3l64,155.8c5.1,12.6,15,24.3,32.1,24.3V191H102.1v-1.9c10.7,0,19.5-3.2,19.5-12,0-1.6-1.6-6.1-2.7-8.3l-12.8-32.2H52.8L50.1,143c-1.3,3.2-2.1,11.7-2.1,19.2,0,14.1,9.6,26.9,23.7,26.9V191H7.1v-1.9C24.7,189.1,40.5,159.8,48.5,139.8Zm5.6-7h50.7L79.2,69.6Z" /></animated.svg>);
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-k</title><path d="M109.6,9h67.7v1.9c-15.4,0-33.7,12.1-46.1,26.6L104.9,67.9l64.4,105c7.2,11.5,15.7,15.9,27.2,15.9V191H129.9L76.2,101.9,69,110.1v60.6c0,12.9,12.9,18.1,24.4,18.1V191H3.5v-2.2c11.5,0,24.4-5.2,24.4-18.1V29.6C27.9,16.7,15,10.9,3.5,10.9V9H93.4v1.9C81.9,10.9,69,16.7,69,29.6V106l51.8-60.5c4.1-4.7,5.5-12.1,5.5-17.9,0-9.3-5.2-16.7-16.7-16.7Z" /></animated.svg>);
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-o</title><path d="M192.1,100c0,50.5-41.6,91-92.1,91S7.9,150.5,7.9,100,49.5,9,100,9,192.1,49.5,192.1,100Zm-45.8,0c0-38.9-3.4-88.4-46.3-88.4S53.7,61.1,53.7,100s3.7,88.4,46.6,88.4S146.3,138.9,146.3,100Z" /></animated.svg>);
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-p</title><path d="M113.3,189.1V191H23.5v-1.9c11.5,0,24.4-5.2,24.4-18.1V29.5c0-12.8-12.9-18.6-24.4-18.6V9.3h66c7.3,0,18-.3,29.5-.3,30.4,0,57.5,22.4,57.5,52.8,0,34.8-27.9,47.4-73.1,47.6H88.9V171C88.9,183.9,101.8,189.1,113.3,189.1ZM88.9,106.7h11.2c26.6-.3,34.8-22.4,34.8-49.5,0-19.7-9.9-46.6-28.5-46.6A87.4,87.4,0,0,0,88.9,12Z" /></animated.svg>);
  logoLetters.push(<animated.svg width="200" height="200" viewBox="0 0 200 200" style={animation} className="letter"><title>outline-i</title><g><path d="M143,62.7a362.1,362.1,0,0,1-45.8,38.9c-9,6.5-18.4,12.6-26.7,20S55,138,51.3,148.5a47.7,47.7,0,0,0,2.8,37.7A29.2,29.2,0,0,0,57,191a354,354,0,0,1,45.8-38.9c9-6.5,18.4-12.6,26.7-20.1s15.5-16.3,19.2-26.8a47.6,47.6,0,0,0-2.8-37.7A29.2,29.2,0,0,0,143,62.7Z" /><path d="M114.2,9a127,127,0,0,0,15.4,13.1,101,101,0,0,1,9,6.8,22.2,22.2,0,0,1,6.5,9,16.1,16.1,0,0,1-.9,12.7c-.3.6-.7,1.1-1,1.6a119.8,119.8,0,0,0-15.4-13.1,100,100,0,0,1-9-6.7,22.5,22.5,0,0,1-6.5-9.1,16.5,16.5,0,0,1,.9-12.7C113.5,10,113.9,9.5,114.2,9Z" /></g></animated.svg>);

  const positionUpdate = e => {
    setMousePositionX(e.pageX);
    setMousePositionY(e.pageY);
  }

  useEffect(() => {
    document.addEventListener('mousemove', e => positionUpdate(e));
  }, [])

  return (
    <>
      <div className="App">
        <div className="page-container">
          <header>
            <div className="letter-container">
              {logoLetters[counter]}
            </div>
            <div className="logo-container">
              <img className="logo-payoff" src={logoPayoff} alt="" />
            </div>
          </header>
          <footer>
            <div className="social-container">
              <a href="https://www.facebook.com/dakopi.eventi" target="_blank" rel="noopener noreferrer"><svg role="img" viewBox="0 0 24 24" className="social-icon"><title>Facebook</title><path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" /></svg></a>
              <a href="https://www.instagram.com/dakopi_eventi/" target="_blank" rel="noopener noreferrer"><svg role="img" viewBox="0 0 24 24" className="social-icon"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg></a>
              <a href="https://wa.me/393493840423" target="_blank" rel="noopener noreferrer"><svg role="img" viewBox="0 0 24 24" className="social-icon"><title>WhatsApp</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" /></svg></a>
              <a href="mailto:martina@dakopi.com"><svg role="img" viewBox="0 0 512 512" className="social-icon"><title>Email</title><path d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788zM51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787L338.213,256L482,112.212V399.787z" /></svg></a>
            </div>
          </footer>
        </div>
        <MousePointer positionX={mousePositionX} positionY={mousePositionY} pointerClass="mouse-pointer-1" />
        <MousePointer positionX={mousePositionX} positionY={mousePositionY} pointerClass="mouse-pointer-2" />
      </div >
      <h6>Copyrigh © Dakopì di Riva Martina - 2020 - Tutti i diritti riservati</h6>
    </>
  );
}

export default App;
