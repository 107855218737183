import React from 'react'

const MousePointer = props => {
    let style = {
        transform: 'translate(' + props.positionX + 'px, ' + props.positionY + 'px)'
    }

    console.log(style);

    return (
        <div className={props.pointerClass} style={style} />
    )
}

export default MousePointer;
